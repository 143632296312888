import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { AuthUserGroups } from "@domain/value/auth/AuthUserGroups";
import { atom, selector, useRecoilState } from "recoil";
import { Auth, Hub } from "aws-amplify";
import { useRouter } from "next/router";
import { UnexpectedError } from "@errors/UnexpectedError";
import * as Sentry from "@sentry/react";
import { setSentryUser } from "@common/sentry/frontEnd/setSentryUser";
import { syncAmplifyConfigureWithAuthState } from "@common/aws/amplify/amplifyConfigure";
import { destroyCookie } from "nookies";
import { signUpEmailCookieKey, signUpUsernameCookieKey } from "packages/member-site/src/constants/platform/cookie/keys";
import { hasShippingRequestsSessionStorageKey, paymentMethodStateSessionStorageKey, signUpStateSessionStorageKey, subSessionStorageKey } from "member-site/src/constants/platform/sessionStorage/keys";
import { redirectUrlCookieKey } from "@constants/auth/signin/redirectUrl";
import * as deepEqual from "deep-equal";
export var clearSignUpStorageItems = function() {
    destroyCookie(null, signUpUsernameCookieKey, {
        path: "/"
    });
    destroyCookie(null, signUpEmailCookieKey, {
        path: "/"
    });
    sessionStorage.removeItem(subSessionStorageKey);
    sessionStorage.removeItem(signUpStateSessionStorageKey);
    sessionStorage.removeItem(paymentMethodStateSessionStorageKey);
};
export var clearRedirectUrl = function() {
    destroyCookie(null, redirectUrlCookieKey, {
        path: "/"
    });
};
export var clearHasShippingRequests = function() {
    sessionStorage.removeItem(hasShippingRequestsSessionStorageKey);
};
export var convertToAuthUserGroups = function(value) {
    if (value.length === 0) {
        return [];
    }
    var groups = [];
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = value[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var item = _step.value;
            switch(item){
                case AuthUserGroups.Admin:
                    groups.push(AuthUserGroups.Admin);
                    break;
                case AuthUserGroups.Warehouse:
                    groups.push(AuthUserGroups.Warehouse);
                    break;
                case AuthUserGroups.Customer:
                    groups.push(AuthUserGroups.Customer);
                    break;
                default:
                    throw Error("予期せぬユーザーグループです");
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return groups;
};
export var initialAuthState = {
    isLoading: true,
    isNotSignedIn: true,
    isSignedInEmailUnVerified: false,
    isSignedIn: false,
    user: null
};
export var authKey = "authKey";
export var authAtom = atom({
    key: authKey,
    default: initialAuthState
});
export var authStateKey = "authStateKey";
export var authSelector = selector({
    key: authStateKey,
    get: function(param) {
        var get = param.get;
        return get(authAtom);
    },
    set: function(param, newValue) {
        var set = param.set;
        return set(authAtom, newValue);
    }
});
export var useAuthState = function() {
    var ref;
    var ref1 = _sliced_to_array(useRecoilState(authSelector), 2), state = ref1[0], setState = ref1[1];
    var router = useRouter();
    var authState = state;
    var trySignIn = function() {
        var _ref = _async_to_generator(function(callback) {
            var session, idToken, payload, ref, user, newState, e;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            6
                        ]);
                        return [
                            4,
                            Auth.currentSession()
                        ];
                    case 1:
                        session = _state.sent();
                        idToken = session.getIdToken();
                        payload = idToken.decodePayload();
                        user = {
                            id: payload["sub"],
                            username: payload["cognito:username"],
                            email: payload["email"],
                            groups: convertToAuthUserGroups((ref = payload["cognito:groups"]) !== null && ref !== void 0 ? ref : [])
                        };
                        setSentryUser(user.id);
                        newState = initialAuthState;
                        // Cognito 的にはメールアドレスの検証が完了していないとログインできない
                        if (payload["email_verified"]) {
                            if (state.isSignedIn && deepEqual(state.user, user)) {
                                return [
                                    2
                                ];
                            }
                            setState(function(prev) {
                                newState = _object_spread_props(_object_spread({}, prev), {
                                    isLoading: false,
                                    isNotSignedIn: false,
                                    isSignedIn: true,
                                    isSignedInEmailUnVerified: false,
                                    user: user
                                });
                                return newState;
                            });
                        } else {
                            if (state.isSignedInEmailUnVerified) return [
                                2
                            ];
                            setState(function(prev) {
                                newState = _object_spread_props(_object_spread({}, prev), {
                                    isLoading: false,
                                    isNotSignedIn: false,
                                    isSignedIn: false,
                                    isSignedInEmailUnVerified: true,
                                    user: user
                                });
                                return newState;
                            });
                        }
                        syncAmplifyConfigureWithAuthState(newState.isSignedIn);
                        if (callback != null) {
                            callback(newState);
                        }
                        return [
                            3,
                            6
                        ];
                    case 2:
                        e = _state.sent();
                        if (!(e === "No current user")) return [
                            3,
                            3
                        ];
                        // Cognito のログインセッションが無い場合
                        setState(function(prev) {
                            return _object_spread_props(_object_spread({}, prev), {
                                isLoading: false
                            });
                        });
                        return [
                            3,
                            5
                        ];
                    case 3:
                        // ストレージのデータ不整合の可能性があるため
                        return [
                            4,
                            trySignOut()
                        ];
                    case 4:
                        _state.sent();
                        _state.label = 5;
                    case 5:
                        return [
                            3,
                            6
                        ];
                    case 6:
                        return [
                            2
                        ];
                }
            });
        });
        return function trySignIn(callback) {
            return _ref.apply(this, arguments);
        };
    }();
    var trySignOut = function() {
        var _ref = _async_to_generator(function() {
            var homePath, callback, e, newState;
            var _arguments = arguments;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        homePath = _arguments.length > 0 && _arguments[0] !== void 0 ? _arguments[0] : process.env.NEXT_PUBLIC_SIGNOUTE_REDIRECT_PATH, callback = _arguments.length > 1 ? _arguments[1] : void 0;
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            4,
                            ,
                            5
                        ]);
                        // Cognito セッションが残っているかチェック
                        // Hub.listen が多重に実行されるのを回避するため
                        return [
                            4,
                            Auth.currentSession()
                        ];
                    case 2:
                        _state.sent();
                        return [
                            4,
                            Auth.signOut()
                        ];
                    case 3:
                        _state.sent();
                        return [
                            3,
                            5
                        ];
                    case 4:
                        e = _state.sent();
                        return [
                            3,
                            5
                        ];
                    case 5:
                        Sentry.setUser(null);
                        syncAmplifyConfigureWithAuthState(false);
                        clearSignUpStorageItems();
                        clearRedirectUrl();
                        clearHasShippingRequests();
                        newState = _object_spread_props(_object_spread({}, initialAuthState), {
                            isLoading: false
                        });
                        setState(newState);
                        if (callback != null) {
                            callback(newState);
                        }
                        if (homePath == null) {
                            throw new UnexpectedError({
                                data: {
                                    homePath: homePath
                                }
                            }, "ホーム画面へのパスが未定義です");
                        }
                        if (!(router.pathname !== homePath)) return [
                            3,
                            7
                        ];
                        return [
                            4,
                            router.push(homePath)
                        ];
                    case 6:
                        _state.sent();
                        _state.label = 7;
                    case 7:
                        return [
                            2
                        ];
                }
            });
        });
        return function trySignOut() {
            return _ref.apply(this, arguments);
        };
    }();
    var getCognitoUserAttributes = function() {
        var _ref = _async_to_generator(function(keys) {
            var session, idToken, payload, result, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, key;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            Auth.currentSession()
                        ];
                    case 1:
                        session = _state.sent();
                        idToken = session.getIdToken();
                        payload = idToken.decodePayload();
                        result = {};
                        _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                        try {
                            for(_iterator = keys[Symbol.iterator](); !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                                key = _step.value;
                                result[key] = payload[key];
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally{
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return != null) {
                                    _iterator.return();
                                }
                            } finally{
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }
                        return [
                            2,
                            result
                        ];
                }
            });
        });
        return function getCognitoUserAttributes(keys) {
            return _ref.apply(this, arguments);
        };
    }();
    var listenAuth = function(trySignInCallback, signOutCallback) {
        Hub.listen("auth", function() {
            var _ref = _async_to_generator(function(data) {
                var _;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            _ = data.payload.event;
                            switch(_){
                                case "signIn":
                                    return [
                                        3,
                                        1
                                    ];
                                case "signOut":
                                    return [
                                        3,
                                        3
                                    ];
                            }
                            return [
                                3,
                                5
                            ];
                        case 1:
                            return [
                                4,
                                trySignIn(trySignInCallback)
                            ];
                        case 2:
                            _state.sent();
                            return [
                                3,
                                5
                            ];
                        case 3:
                            return [
                                4,
                                trySignOut(process.env.NEXT_PUBLIC_SIGNOUTE_REDIRECT_PATH, signOutCallback)
                            ];
                        case 4:
                            _state.sent();
                            return [
                                3,
                                5
                            ];
                        case 5:
                            return [
                                2
                            ];
                    }
                });
            });
            return function(data) {
                return _ref.apply(this, arguments);
            };
        }());
    };
    var isAdmin = (ref = state.user) === null || ref === void 0 ? void 0 : ref.groups.includes(AuthUserGroups.Admin);
    return {
        authState: authState,
        trySignIn: trySignIn,
        getCognitoUserAttributes: getCognitoUserAttributes,
        trySignOut: trySignOut,
        listenAuth: listenAuth,
        isAdmin: isAdmin
    };
};
