import React from 'react';

import {useAuthRevalidation} from '@presentation/hooks/auth/useAuthRevalidation';

const AuthStateProvider = ({children}: {children: React.ReactNode}) => {
  useAuthRevalidation();
  return <>{children}</>;
};

export default AuthStateProvider;
