import {AppProps} from 'next/app';
import Head from 'next/head';
import {RecoilRoot} from 'recoil';
import AuthStateProvider from '../components/auth/AuthStateProvider';
import {CssBaseline} from '@mui/material';
import '@presentation/components/common/resetStyle/ress.scss';
import '@presentation/components/common/globalStyles/global.scss';
import awsExports from '../aws-exports';
import {configureAmplify} from '@common/aws/amplify/amplifyConfigure';
import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';
import {baseTitle} from '@constants/seo/baseTitle';
import Favicon from '@presentation/components/layout/Favicon';
import {initSentry} from '@common/sentry/frontEnd/initSentry';
import GoogleTagManager, {
  GoogleTagManagerId,
  googleTagManagerId,
} from '@presentation/components/googleTagManager/GoogleTagManager';

configureAmplify(awsExports);
initSentry();

function CustomApp({Component, pageProps}: AppProps) {
  const router = useRouter();
  const [isLP, setIsLP] = useState<boolean>(false);

  useEffect(() => {
    if (!router.isReady) return;
    if (router.asPath === '/') {
      setIsLP(true);
    }
  }, [router]);

  return (
    <>
      <GoogleTagManager
        googleTagManagerId={googleTagManagerId as GoogleTagManagerId}
      />
      <RecoilRoot>
        <AuthStateProvider>
          <Head>
            <title>{baseTitle}</title>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
          </Head>
          <Favicon />
          {/*LP は <CssBaseline /> なし前提でコーディングされたものなので <CssBaseline /> を使わない*/}
          {!isLP && <CssBaseline />}
          <main>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <Component {...pageProps} />
          </main>
        </AuthStateProvider>
      </RecoilRoot>
    </>
  );
}

export default CustomApp;
