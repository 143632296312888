// import * as SentryServerless from '@sentry/serverless';
import { BrowserTracing } from "@sentry/tracing";
import { Dedupe as DedupeIntegration } from "@sentry/integrations";
export var initialOptions = {
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    release: process.env.NEXT_PUBLIC_ENV !== "local" ? process.env.AWS_APP_ID : "local",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            tracingOrigins: [
                "localhost",
                "dev.tadaoki.jp",
                "stg.tadaoki.jp",
                "tadaoki.jp",
                /^\//, 
            ]
        }),
        new DedupeIntegration(), 
    ],
    ignoreErrors: [
        "Non-Error exception captured"
    ]
};
