import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { useRouter } from "next/router";
import { useScreenState } from "@presentation/components/screen/screenStateHandler/screenState";
import { useAuthState } from "@presentation/components/auth/AuthState";
import { useEffect } from "react";
export var useAuthRevalidation = function() {
    var ref = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, trySignInCallback = ref.trySignInCallback, signOutCallback = ref.signOutCallback;
    var ref1 = useScreenState(), showLoading = ref1.showLoading, hideLoading = ref1.hideLoading;
    var ref2 = useAuthState(), trySignIn = ref2.trySignIn, listenAuth = ref2.listenAuth;
    var router = useRouter();
    useEffect(function() {
        listenAuth(trySignInCallback, signOutCallback);
    }, []);
    useEffect(function() {
        _async_to_generator(function() {
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        // このサイトへのアクセスを継続したまま（手動などで）ログインセッションが削除された場合に
                        // 最新のログイン状態を保つため画面が遷移するたびにログイン状態確認を実行する
                        // （Cognito へのアクセスが発生するわけではなく SessionStorage の中身を見にいくだけ）
                        showLoading();
                        return [
                            4,
                            trySignIn(trySignInCallback)
                        ];
                    case 1:
                        _state.sent();
                        hideLoading();
                        return [
                            2
                        ];
                }
            });
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        router.pathname
    ]);
};
