import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { AbstractError } from "@errors/AbstractError";
import { atom, selector, useRecoilState } from "recoil";
import { UnexpectedError } from "@errors/UnexpectedError";
// 初期状態
var _initialScreenState = {
    isLoading: false,
    error: null
};
var _screenAtomKey = "screenKey";
export var screenAtom = atom({
    key: _screenAtomKey,
    default: _initialScreenState
});
var _screenSelectorKey = "screenStateKey";
export var screenSelector = selector({
    key: _screenSelectorKey,
    get: function(param) {
        var get = param.get;
        return get(screenAtom);
    },
    set: function(param, newValue) {
        var set = param.set;
        return set(screenAtom, newValue);
    }
});
export var useScreenState = function() {
    var ref = _sliced_to_array(useRecoilState(screenSelector), 2), state = ref[0], setState = ref[1];
    return {
        screenState: state,
        resetScreenState: function() {
            setState(function() {
                return _initialScreenState;
            });
        },
        showLoading: function() {
            setState(function(prev) {
                return _object_spread_props(_object_spread({}, prev), {
                    isLoading: true
                });
            });
        },
        hideLoading: function() {
            setState(function(prev) {
                return _object_spread_props(_object_spread({}, prev), {
                    isLoading: false
                });
            });
        },
        makeErrorScreen: function(error) {
            var _error;
            if (_instanceof(error, AbstractError)) {
                _error = error;
            } else {
                _error = new UnexpectedError({
                    data: {
                        error: error
                    }
                });
            }
            setState(function(prev) {
                return _object_spread_props(_object_spread({}, prev), {
                    error: _error
                });
            });
        }
    };
};
