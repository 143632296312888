var removeUnTrackingDataFromRequest = function(params) {
    var url = params.url, method = params.method, headers = params.headers, baseURL = params.baseURL, responseType = params.responseType, data = params.data;
    return {
        url: url,
        method: method,
        headers: headers,
        baseURL: baseURL,
        responseType: responseType,
        data: data
    };
};
var removeUnTrackingDataFromResponse = function(params) {
    if (!params) {
        return {};
    }
    var data = params.data, status = params.status, statusText = params.statusText, headers = params.headers;
    return {
        data: data,
        status: status,
        statusText: statusText,
        headers: headers
    };
};
export var removeUnTrackingData = function(params) {
    var requestData, responseData = {};
    if ("response" in params) {
        var config = params.config, response = params.response;
        requestData = removeUnTrackingDataFromRequest(config);
        responseData = removeUnTrackingDataFromResponse(response);
    } else if ("config" in params) {
        var response1 = params;
        requestData = removeUnTrackingDataFromRequest(response1.config);
        responseData = removeUnTrackingDataFromResponse(response1);
    } else {
        requestData = removeUnTrackingDataFromRequest(params);
    }
    return {
        config: requestData,
        response: responseData
    };
};
