import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { Amplify } from "aws-amplify";
export var configureAmplify = function(awsExports) {
    Amplify.configure(_object_spread_props(_object_spread({}, awsExports), {
        ssr: true
    }));
};
export var syncAmplifyConfigureWithAuthState = function(isSignedIn) {
    if (isSignedIn) {
        Amplify.configure({
            aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
        });
    } else {
        Amplify.configure({
            aws_appsync_authenticationType: "AWS_IAM"
        });
    }
};
